@import 'src/styles/gaps';
@import 'src/styles/colors';
@import 'src/styles/fonts';
@import 'src/styles/variables';
@import 'src/styles/typography';
@import 'src/styles/functions';

.table {
	background-color: $white;
	border-radius: 20px;
	padding: $gap-4xl;
	padding-top: 0;
	display: flex;
	flex-direction: column;

	.settingsColumn {
		display: flex;
		flex-direction: column;
	}

	.infoColumns {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		position: relative;
	}
}

.header {
	display: grid;
	grid-template-columns: 37rem 1fr 1fr 1fr;
}

.body {
	display: grid;
	grid-template-columns: 37rem 1fr 1fr 1fr;
	border-bottom: 1px solid #e6ecf5;
}

.headerCell {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-top: 5rem;

	border-right: 1px solid #e6ecf5;

	.title {
		color: #0a2653;
		margin-bottom: $gap-s;

		@include heading-l;

		font-size: 4rem !important;
	}

	.tariffDate {
		color: #9aa4b9;

		@include text-m;

		font-size: 2rem !important;
	}
}

.tariffCell {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 5rem 1rem 0;

	border-right: 1px solid #e6ecf5;

	.tariffName {
		color: #06519e;
		margin-bottom: $gap-s;

		@include text-xl;

		font-size: 2rem !important;

		.goldFishIcon {
			position: absolute;
			right: 8px;
			top: 2rem;
			width: 3rem;
			height: 3rem;
		}
	}

	.tariffDescription {
		color: #6f7d96;
		min-height: 53px;
		text-align: center;

		@include text-s;

		font-size: 1.3rem !important;
	}
}

.labelCell {
	display: flex;
	align-items: center;
	height: 72px;
	padding: $gap-l 0;
	color: #0a2653;
	border-bottom: 1px solid #e6ecf5;
	border-right: 1px solid #e6ecf5;

	@include text-m;

	font-size: 1.6rem !important;

	&:first-child {
		font-weight: 600;
	}
}

.labelSetting {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: $gap-xs;
	height: 72px;
	padding: $gap-l 0;
	color: #0a2653;
	border-bottom: 1px solid #e6ecf5;
	border-right: 1px solid #e6ecf5;

	.settingValue {
		@include text-m;

		font-family: 'PTSans' !important;
		font-size: 1.6rem !important;
	}

	.boldSettingValue {
		font-family: 'PTSans Bold' !important;
	}

	.settingDescription {
		color: #6f7d96;

		@include text-xs;

		font-size: 1.3rem !important;
	}

	.withoutCommission {
		background-color: #009b47;
		padding: pxToRem(3) pxToRem(20);
		border-radius: $main-border-radius;
		color: $white;

		@include text-m;

		font-size: 1.4rem !important;
	}
}

.tooltipContainer {
	@include text-m;

	font-size: 1.4rem !important;
}

.hint {
	margin-left: $gap-m;
	flex-shrink: 0;
	width: 2rem;
	height: 2rem;
}

.currencyIcon {
	font-family: 'PTSans Bold';
}

.currentTariff {
	margin: 0;
	padding: $gap-m 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #0a2653;
	text-align: center;

	@include text-m;
}

.bestChoiceColumn {
	border-right: 2px solid #4587e8;
	border-left: 2px solid #4587e8;
}

.bestChoiceLabel {
	position: absolute;
	bottom: calc(100%);
	left: -2px;
	width: calc(100% + 4px);
	display: flex;
	align-items: center;
	justify-content: center;
	height: 4rem;
	color: #ebf2ff;
	border-radius: $main-border-radius $main-border-radius 0 0;
	background: #4587e8;

	@include text-m;
	font-size: 2rem !important;
}

.choiceButton {
	margin: $gap-m $gap-s;
	padding: 0 $gap-s;

	@include text-m;
	font-size: 1.6rem !important;
}

.showMoreButtonWrapper {
	border-right: 1px solid #e6ecf5;
}

.showMoreButton {
	margin: $gap-m;
	margin-left: 0;
	padding: 0 $gap-l 0 0;
	width: 34rem;
	border-radius: $main-border-radius;
	text-align: start;

	@include text-m;
	font-size: 1.6rem !important;

	&:hover {
		color: $text-icons-primary !important;
		background: none !important;
	}
}

.buttonWrapper {
	border-right: 1px solid #e6ecf5;
}

.infoDescription {
	margin-top: pxToRem(24);
	color: #6f7d96;

	@include text-s;
	font-size: pxToRem(20) !important;

	.infoDescriptionLink {
		color: #5390e9;
		text-decoration: none;
	}
}
