@import 'src/styles/typography';
@import 'src/styles/gaps';
@import 'src/styles/functions';
@import 'src/styles/variables';

.contentContainer {
	display: flex;
	flex-direction: column;
	gap: pxToRem(26);
}

.description {
	color: #6f7d96;

	@include text-xl;

	font-size: pxToRem(20) !important;
}

.input {
	width: 100%;
	height: 4rem;
	background: #f3f6fa;
	border-radius: $main-border-radius;
}

.button {
	font-size: 1.4rem;
}
