@import 'src/styles/gaps';
@import 'src/styles/colors';
@import 'src/styles/fonts';
@import 'src/styles/variables';
@import 'src/styles/typography';

.container {
	margin: $gap-m;
}

.icon {
	width: 2rem;
	height: 2rem;
}

.tariffHeader {
	color: white;
	margin-bottom: 0.8rem;

	@include heading-m;

	font-size: 3.4rem !important;
}

.tariffDate {
	color: #9aa4b9;
	margin-bottom: 1.3rem;

	@include text-m;
	font-size: 1.4rem !important;
}

.tariff {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.bestChoiceLabel {
	position: relative;
	top: 10px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 3.5rem;
	color: #ebf2ff;
	border-radius: $main-border-radius $main-border-radius 0 0;
	background: #4587e8;

	@include text-m;
	font-size: 1.4rem !important;
}

.tariffSection {
	display: flex;
	flex-direction: column;
	gap: $gap-s;
	background-color: white;
	padding: $gap-xl $gap-l;
	border-radius: $main-border-radius;
}

.tariffInfo {
	display: flex;
	flex-direction: column;

	.tariffName {
		color: #4587e8;
		margin-bottom: 1rem;

		@include heading-s;
		font-size: 2rem !important;
	}

	.tariffPrompt {
		color: #9aa4b9;
		margin-bottom: 1rem;

		@include text-m;
		font-size: 1.3rem !important;
	}

	.price {
		color: #0a2653;
		margin-bottom: $gap-s;

		@include heading-xs;
	}
}

.tariffParameters {
	display: flex;
	flex-direction: column;
	gap: $gap-xs;
	margin-bottom: 2rem;

	.tariffSettingPoint {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 1.2rem;
		gap: 0.3rem;
		border-bottom: 1px solid #e6ecf5;

		&:not(:first-child) {
			border-bottom: 1px solid #e6ecf5;
		}

		.tariffSettingTitle {
			display: flex;
			flex-direction: row;
		}

		.tariffSettingLabel {
			color: #9aa4b9;
			text-align: center;

			@include text-m;

			font-size: 1.4rem !important;
		}

		.hint {
			margin-left: 9px;
			flex-shrink: 0;
			width: 2rem;
			height: 2rem;
		}

		.tariffSettingValue {
			color: #0a2653;

			@include text-m;

			font-size: 1.4rem !important;
		}

		.boldValue {
			font-family: 'PTSans Bold';
		}

		.tariffSettingDescription {
			color: #6f7d96;

			@include text-s;

			font-size: 1.4rem !important;
		}
	}
}

.tooltipContainer {
	@include text-m;

	font-size: 1.4rem !important;
}

.showMoreButton {
	margin-bottom: 1rem;
}
