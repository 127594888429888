@import 'src/styles/typography';
@import 'src/styles/gaps';
@import 'src/styles/functions';
@import 'src/styles/variables';

.antContent {
	:global {
		.ant-modal-content {
			padding: pxToRem(48);
		}
		.ant-modal-confirm-paragraph {
			max-width: 100%;
		}
		.ant-modal-close {
			&:hover {
				background-color: inherit;
			}
		}
	}
}

.closeIcon {
	svg {
		width: 2rem;
		height: 2rem;
	}
}

.modalTitle {
	color: #0a2653;
	margin-bottom: pxToRem(16);

	@include heading-m;

	font-size: pxToRem(30) !important;
}
